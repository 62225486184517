body {
    background-color: #fffaed;
    font-family: 'Noto Serif SC', serif;
}

h4, h5 {
    line-height: 1.8;
}

.offset-container {
    margin-top: -8em;
}

.input-group {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;
}

.input-group label {
    padding: 12px 90px 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    -webkit-transition: color 200ms ease-in;
    transition: color 200ms ease-in;
    overflow: hidden;
}
.input-group label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    background-color: #e16355;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    z-index: -1;
}
.input-group label:after {
    width: 32px;
    height: 32px;
    content: "";
    border: 2px solid #d1d7dc;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    -webkit-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}

.input-group input:checked ~ label, .input-group input:checked ~ .ans-indicator {
    color: #fff;
}

.input-group input:checked ~ label:before {
    -webkit-transform: translate(-110%, -110%) scale3d(120, 120, 1);
    transform: translate(-110%, -110%) scale3d(120, 120, 1);
    opacity: 1;
}

.input-group input:checked ~ label:after {
    background-color: #6b7b97;
    border-color: #6b7b97;
}

.ans-indicator {
    right: 20px;
    font-size: 1.5em;
    top: 49%;
    transform: translateY(-50%);
    z-index: 99;
}

.input-group input {
    width: 32px;
    height: 32px;
    -webkit-box-ordinal-group: 2;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
}

.form {
    padding: 0 16px;
    max-width: 550px;
    margin: 50px auto;
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
}

.divider {
    border-bottom: 1px dashed #9F8668;
}

ol {
    clear: both;
    list-style: none;
    padding: 0;
    margin: 0;
}
ol li {
    margin: 1em 0 2.5em;
    display: block;
    position: relative;
    counter-increment: inst;
}
ol li::before {
    content: counter(inst);
    background: #c6cbd4;
    color: #fff;
    font-size: 1em;
    font-weight: 700;
    font-style: italic;
    text-shadow: 1px 1px rgba(121, 131, 148, 0.5);
    border-radius: 0 0.675em 0 0;
    font-size: 2em;
    text-align: center;
    padding-top: 0;
    top: -1.2em;
    height: 1.7em;
    width: 2em;
    display: block;
    transition: all 0.2s ease-in-out;
    z-index: -1;
}

.numbering-border-bottom {
    border-bottom: 1px solid #c6cbd4;
}

.btn-submit, .btn-reset {
    background: transparent;
    border: 1px solid #E46964;
    color: #E46964;
    cursor: pointer;
}

.btn-submit:hover, .btn-reset:hover {
    background: #E46964;
    color: #fff;
    cursor: pointer;
}

.content-result {
    background: rgba(247, 224, 222, .4);
}

.content-result h5 {
    line-height: 1.7em;
}

footer {
    background: #f2f2f2;
    font-size: 13px;
}

.progress {
    height: 2.5em;
    font-size: 1em;
}

.progress-bar {
    text-align: left;
    padding-left: 20px;
}

@media only screen and (max-width: 600px) {
    .offset-container {
        margin-top: -5em;
    }

    h4 {
        font-size: 1.2em;
    }

    h5 {
        font-size: 1.1em;
    }
}
